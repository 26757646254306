(() => {
  'use strict';

  document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.nav-link.dropdown-toggle').forEach(link => link.addEventListener('click', function (e) {
      e.preventDefault();
    }));
  });
})();


  /*!
    * Native JavaScript for Bootstrap v3.0.10 (https://thednp.github.io/bootstrap.native/)
    * Copyright 2015-2020 © dnp_theme
    * Licensed under MIT (https://github.com/thednp/bootstrap.native/blob/master/LICENSE)
    */
  (function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
      typeof define === 'function' && define.amd ? define(factory) :
        (global = global || self, global.BSN = factory());
  }(this, (function () {
    'use strict';

    var transitionEndEvent = 'webkitTransition' in document.head.style ? 'webkitTransitionEnd' : 'transitionend';

    var supportTransition = 'webkitTransition' in document.head.style || 'transition' in document.head.style;

    var transitionDuration = 'webkitTransition' in document.head.style ? 'webkitTransitionDuration' : 'transitionDuration';

    function getElementTransitionDuration(element) {
      var duration = supportTransition ? parseFloat(getComputedStyle(element)[transitionDuration]) : 0;
      duration = typeof duration === 'number' && !isNaN(duration) ? duration * 1000 : 0;
      return duration;
    }

    function emulateTransitionEnd(element, handler) {
      var called = 0, duration = getElementTransitionDuration(element);
      duration ? element.addEventListener(transitionEndEvent, function transitionEndWrapper(e) {
        !called && handler(e), called = 1;
        element.removeEventListener(transitionEndEvent, transitionEndWrapper);
      })
        : setTimeout(function () { !called && handler(), called = 1; }, 17);
    }

    function queryElement(selector, parent) {
      var lookUp = parent && parent instanceof Element ? parent : document;
      return selector instanceof Element ? selector : lookUp.querySelector(selector);
    }

    function bootstrapCustomEvent(eventName, componentName, related) {
      var OriginalCustomEvent = new CustomEvent(eventName + '.bs.' + componentName, { cancelable: true });
      OriginalCustomEvent.relatedTarget = related;
      return OriginalCustomEvent;
    }

    function dispatchCustomEvent(customEvent) {
      this && this.dispatchEvent(customEvent);
    }

    //function Collapse(element, options) {
    //  options = options || {};
    //  var self = this;
    //  var accordion = null,
    //    collapse = null,
    //    activeCollapse,
    //    activeElement,
    //    showCustomEvent,
    //    shownCustomEvent,
    //    hideCustomEvent,
    //    hiddenCustomEvent;
    //  function openAction(collapseElement, toggle) {
    //    dispatchCustomEvent.call(collapseElement, showCustomEvent);
    //    if (showCustomEvent.defaultPrevented) { return; }
    //    collapseElement.isAnimating = true;
    //    collapseElement.classList.add('collapsing');
    //    collapseElement.classList.remove('collapse');
    //    collapseElement.style.height = (collapseElement.scrollHeight) + "px";
    //    emulateTransitionEnd(collapseElement, function () {
    //      collapseElement.isAnimating = false;
    //      collapseElement.setAttribute('aria-expanded', 'true');
    //      toggle.setAttribute('aria-expanded', 'true');
    //      collapseElement.classList.remove('collapsing');
    //      collapseElement.classList.add('collapse');
    //      collapseElement.classList.add('show');
    //      collapseElement.style.height = '';
    //      dispatchCustomEvent.call(collapseElement, shownCustomEvent);
    //    });
    //  }
    //  function closeAction(collapseElement, toggle) {
    //    dispatchCustomEvent.call(collapseElement, hideCustomEvent);
    //    if (hideCustomEvent.defaultPrevented) { return; }
    //    collapseElement.isAnimating = true;
    //    collapseElement.style.height = (collapseElement.scrollHeight) + "px";
    //    collapseElement.classList.remove('collapse');
    //    collapseElement.classList.remove('show');
    //    collapseElement.classList.add('collapsing');
    //    collapseElement.offsetWidth;
    //    collapseElement.style.height = '0px';
    //    emulateTransitionEnd(collapseElement, function () {
    //      collapseElement.isAnimating = false;
    //      collapseElement.setAttribute('aria-expanded', 'false');
    //      toggle.setAttribute('aria-expanded', 'false');
    //      collapseElement.classList.remove('collapsing');
    //      collapseElement.classList.add('collapse');
    //      collapseElement.style.height = '';
    //      dispatchCustomEvent.call(collapseElement, hiddenCustomEvent);
    //    });
    //  }
    //  self.toggle = function (e) {
    //    if (e && e.target.tagName === 'A' || element.tagName === 'A') { e.preventDefault(); }
    //    if (element.contains(e.target) || e.target === element) {
    //      if (!collapse.classList.contains('show')) { self.show(); }
    //      else { self.hide(); }
    //    }
    //  };
    //  self.hide = function () {
    //    if (collapse.isAnimating) { return; }
    //    closeAction(collapse, element);
    //    element.classList.add('collapsed');
    //  };
    //  self.show = function () {
    //    if (accordion) {
    //      activeCollapse = accordion.getElementsByClassName("collapse show")[0];
    //      activeElement = activeCollapse && (queryElement(("[data-target=\"#" + (activeCollapse.id) + "\"]"), accordion)
    //        || queryElement(("[href=\"#" + (activeCollapse.id) + "\"]"), accordion));
    //    }
    //    if (!collapse.isAnimating) {
    //      if (activeElement && activeCollapse !== collapse) {
    //        closeAction(activeCollapse, activeElement);
    //        activeElement.classList.add('collapsed');
    //      }
    //      openAction(collapse, element);
    //      element.classList.remove('collapsed');
    //    }
    //  };
    //  self.dispose = function () {
    //    element.removeEventListener('click', self.toggle, false);
    //    delete element.Collapse;
    //  };
    //  element = queryElement(element);
    //  element.Collapse && element.Collapse.dispose();
    //  var accordionData = element.getAttribute('data-parent');
    //  showCustomEvent = bootstrapCustomEvent('show', 'collapse');
    //  shownCustomEvent = bootstrapCustomEvent('shown', 'collapse');
    //  hideCustomEvent = bootstrapCustomEvent('hide', 'collapse');
    //  hiddenCustomEvent = bootstrapCustomEvent('hidden', 'collapse');
    //  collapse = queryElement(options.target || element.getAttribute('data-target') || element.getAttribute('href'));
    //  collapse.isAnimating = false;
    //  accordion = element.closest(options.parent || accordionData);
    //  if (!element.Collapse) {
    //    element.addEventListener('click', self.toggle, false);
    //  }
    //  element.Collapse = self;
    //}

    function setFocus(element) {
      element.focus ? element.focus() : element.setActive();
    }

    function Dropdown(element, option) {
      var self = this,
        showCustomEvent,
        shownCustomEvent,
        hideCustomEvent,
        hiddenCustomEvent,
        relatedTarget = null,
        parent, menu, menuItems = [],
        persist;
      function preventEmptyAnchor(anchor) {
        (anchor.href && anchor.href.slice(-1) === '#' || anchor.parentNode && anchor.parentNode.href
          && anchor.parentNode.href.slice(-1) === '#') && this.preventDefault();
      }
      function toggleDismiss() {
        var action = element.open ? 'addEventListener' : 'removeEventListener';
        document[action]('click', dismissHandler, false);
        document[action]('keydown', preventScroll, false);
        document[action]('keyup', keyHandler, false);
        document[action]('focus', dismissHandler, false);
      }
      function dismissHandler(e) {
        var eventTarget = e.target,
          hasData = eventTarget && (eventTarget.getAttribute('data-toggle')
            || eventTarget.parentNode && eventTarget.parentNode.getAttribute
            && eventTarget.parentNode.getAttribute('data-toggle'));
        if (e.type === 'focus' && (eventTarget === element || eventTarget === menu || menu.contains(eventTarget))) {
          return;
        }
        if ((eventTarget === menu || menu.contains(eventTarget)) && (persist || hasData)) { return; }
        else {
          relatedTarget = eventTarget === element || element.contains(eventTarget) ? element : null;
          self.hide();
        }
        preventEmptyAnchor.call(e, eventTarget);
      }
      function clickHandler(e) {
        relatedTarget = element;
        self.show();
        preventEmptyAnchor.call(e, e.target);
      }
      function preventScroll(e) {
        var key = e.which || e.keyCode;
        if (key === 38 || key === 40) { e.preventDefault(); }
      }
      function keyHandler(e) {
        var key = e.which || e.keyCode,
          activeItem = document.activeElement,
          isSameElement = activeItem === element,
          isInsideMenu = menu.contains(activeItem),
          isMenuItem = activeItem.parentNode === menu || activeItem.parentNode.parentNode === menu,
          idx = menuItems.indexOf(activeItem);
        if (isMenuItem) {
          idx = isSameElement ? 0
            : key === 38 ? (idx > 1 ? idx - 1 : 0)
              : key === 40 ? (idx < menuItems.length - 1 ? idx + 1 : idx) : idx;
          menuItems[idx] && setFocus(menuItems[idx]);
        }
        if ((menuItems.length && isMenuItem
          || !menuItems.length && (isInsideMenu || isSameElement)
          || !isInsideMenu)
          && element.open && key === 27
        ) {
          self.toggle();
          relatedTarget = null;
        }
      }
      self.show = function () {
        showCustomEvent = bootstrapCustomEvent('show', 'dropdown', relatedTarget);
        dispatchCustomEvent.call(parent, showCustomEvent);
        if (showCustomEvent.defaultPrevented) { return; }
        menu.classList.add('show');
        parent.classList.add('show');
        element.setAttribute('aria-expanded', true);
        element.open = true;
        element.removeEventListener('click', clickHandler, false);
        setTimeout(function () {
          setFocus(menu.getElementsByTagName('INPUT')[0] || element);
          toggleDismiss();
          shownCustomEvent = bootstrapCustomEvent('shown', 'dropdown', relatedTarget);
          dispatchCustomEvent.call(parent, shownCustomEvent);
        }, 1);
      };
      self.hide = function () {
        hideCustomEvent = bootstrapCustomEvent('hide', 'dropdown', relatedTarget);
        dispatchCustomEvent.call(parent, hideCustomEvent);
        if (hideCustomEvent.defaultPrevented) { return; }
        menu.classList.remove('show');
        parent.classList.remove('show');
        element.setAttribute('aria-expanded', false);
        element.open = false;
        toggleDismiss();
        setFocus(element);
        setTimeout(function () {
          element.Dropdown && element.addEventListener('click', clickHandler, false);
        }, 1);
        hiddenCustomEvent = bootstrapCustomEvent('hidden', 'dropdown', relatedTarget);
        dispatchCustomEvent.call(parent, hiddenCustomEvent);
      };
      self.toggle = function () {
        if (parent.classList.contains('show') && element.open) { self.hide(); }
        else { self.show(); }
      };
      self.dispose = function () {
        if (parent.classList.contains('show') && element.open) { self.hide(); }
        element.removeEventListener('click', clickHandler, false);
        delete element.Dropdown;
      };
      element = queryElement(element);
      element.Dropdown && element.Dropdown.dispose();
      parent = element.parentNode;
      menu = queryElement('.dropdown-menu', parent);
      Array.from(menu.children).map(function (child) {
        child.children.length && (child.children[0].tagName === 'A' && menuItems.push(child.children[0]));
        child.tagName === 'A' && menuItems.push(child);
      });
      if (!element.Dropdown) {
        !('tabindex' in menu) && menu.setAttribute('tabindex', '0');
        element.addEventListener('click', clickHandler, false);
      }
      persist = option === true || element.getAttribute('data-persist') === 'true' || false;
      element.open = false;
      element.Dropdown = self;
    }

    var componentsInit = {};

    function initializeDataAPI(Constructor, collection) {
      Array.from(collection).map(function (x) { return new Constructor(x); });
    }
    function initCallback(lookUp) {
      lookUp = lookUp || document;
      for (var component in componentsInit) {
        initializeDataAPI(componentsInit[component][0], lookUp.querySelectorAll(componentsInit[component][1]));
      }
    }

    //componentsInit.Collapse = [Collapse, '[data-toggle="collapse"]'];
    componentsInit.Dropdown = [Dropdown, '#Header [data-toggle="dropdown"]'];
    document.body ? initCallback() : document.addEventListener('DOMContentLoaded', function initWrapper() {
      initCallback();
      document.removeEventListener('DOMContentLoaded', initWrapper, false);
    }, false);

    function removeElementDataAPI(ConstructorName, collection) {
      Array.from(collection).map(function (x) { return x[ConstructorName].dispose(); });
    }
    function removeDataAPI(lookUp) {
      lookUp = lookUp || document;
      for (var component in componentsInit) {
        removeElementDataAPI(component, lookUp.querySelectorAll(componentsInit[component][1]));
      }
    }

    var version = "3.0.10";

    var index = {
      Dropdown: Dropdown,
      initCallback: initCallback,
      removeDataAPI: removeDataAPI,
      componentsInit: componentsInit,
      Version: version
    };

    return index;

  })));
